<template>
  <div class="h-100">
    <router-view></router-view>
    <vue-ins-progress-bar></vue-ins-progress-bar>
  </div>
</template>

<script>
import PageOptions from "./config/PageOptions.vue";

export default {
  name: "app",
  data() {
    return {
      pageOptions: PageOptions,
    };
  },
  methods: {
    handleScroll: function () {
      PageOptions.pageBodyScrollTop = window.scrollY;
    },
  },
  mounted() {
    this.$insProgress.finish();
  },
  created() {
    PageOptions.pageBodyScrollTop = window.scrollY;

    window.addEventListener("scroll", this.handleScroll);

    this.$insProgress.start();

    this.$router.beforeEach((to, from, next) => {
      this.$insProgress.start();
      next();
    });
    this.$router.afterEach(() => {
      this.$insProgress.finish();
    });
  },
};
</script>
