import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routeModules = require.context("./groups", true, /routes\.js$/);
const routes = [];

routeModules.keys().forEach((fileName) => {
  const routeModule = routeModules(fileName).default;
  routes.push(...routeModule);
});

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  next();
});

export default router;
