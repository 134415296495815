export default [
  {
    path: "/privacy-policy",
    component: () => import("@/pages/public/PrivacyPolicy.vue"),
  },
  {
    path: "/report",
    component: () => import("@/pages/public/Report.vue"),
  },
  {
    path: "/payment",
    component: () => import("@/pages/public/Payment.vue"),
  },
  {
    path: "/status/:id",
    component: () => import("@/pages/public/Status.vue"),
  },
  {
    path: "/home/",
    component: () => import("@/pages/public/Home.vue"),
  },
  {
    path: "/registrasi",
    component: () => import("@/pages/public/Registrasi.vue"),
  },
];
