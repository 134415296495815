export default [
  {
    path: "/",
    component: () => import("@/pages/public/Home.vue"),
  },
  {
    path: "*",
    component: () => import("@/pages/public/Home.vue"),
  },
  {
    path: "/forbidden",
    component: () => import("@/pages/Forbidden.vue"),
  },
];
